.login-form {
    max-width: 600px;
    padding-top: 100px;
    margin-left: auto;
    margin-right: auto;
}

.main-login-form {
    padding: 20px;
    border: 2px solid #e6e6ee;   
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.title-login-form {
    padding: 10px;
    border: 2px solid #e6e6ee;   
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}