.filter-map {
    width: 280px;
    margin-bottom: 15px;
}
.select-filter-map{
    /* width: 300px; */
    width: 280px;
}

@media (max-width: 767px) {
    .select-filter-map{
        max-width: 100%;
        width: 100%;
    }
    .filter-map {
        width: 100%;
    }
  }

  .icon {
      width: 20px;
      height: 20px;
  }
