
/* .map-content {
    height: 100%;
} */
.height100 {
    height: 100%;
}
.height90 {
    height: 90%;
}
.disabled-filter {
    opacity: 0.4;
}
.disabled-pointer-filter {
    pointer-events: none;
    opacity: 0.4;
}