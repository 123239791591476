.map {
    min-height: 500px;
    width: 100%;
}

.markercluster-map{
    height: 90%;
    width: 100%;
}

html, body, #map {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
.is-collapse .content {
    z-index: -1!important;
}

.map-content {
    height: 100%;
}

.height100 {
    height: 100%;
}

.height98 {
    height: 98%;
}
.height90 {
    height: 90%;
}

.disabled-filter {
    opacity: 0.4;
}

.disabled-pointer-filter {
    pointer-events: none;
    opacity: 0.4;
}

.address-filter-map {
    width: 300px;
    position: absolute;
    z-index: 998;
    padding-top: 8px;
    padding-left: 8px;

}

.ant-select-dropdown {
    width: auto
}

.object-information {
    height: 600px;
    position: absolute;
    width: 340px;
    z-index: 999;
    right: 8px;
    top: 8px;
    background: #ffffff;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #e0e5ec;
}

.leaflet-bottom {
    z-index: 998;
}

@media (height: 640px) and (width: 360px) {
    .object-information {
        width: 340px;
        height: 100%;
    }

    .house-description {
        /* width: 340px; */
        height: 480px;
        overflow-x: auto;
    }
}

@media (height: 568px) and (width: 320px) {
    .object-information {
        width: 300px;
        height: 100%;
    }
    .house-description {
        width: 340px;
        height: 420px;
        overflow: auto;        
    }
}

.icon-close{
    width: 20px;
    height: 20px;
}
/* 

@media (max-height: 700px) and (max-width: 380px) {
    .object-information {
        overflow: auto;
        width: 300px;
    }

    .description-object-information {
        width: 340px;
    }
}

@media (max-height: 768px){
    .object-information {
        max-height: 588px;
    }
}

@media (max-height: 568px){
    .object-information {
        max-height: 420px;
        overflow: auto;
    }
} */



.info-address-exit {
    margin-bottom: 10px;
    border-bottom: 1px solid #f0f1f4;
    text-align: end;
    padding-bottom: 8px;
}