  #notfound {
    position: relative;
    height: 100%;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

  .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../res/DUBOVAYROSA.jpg) 50% no-repeat;
        background-size: cover;
        z-index: -1;
        opacity: .3;
    }
  
  .notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    padding: 0px 15px;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 150px;
    line-height: 150px;
    margin-bottom: 45px;
  }

  .notfound .notfound-404 h1 {
    font-size: 186px;
    font-weight: 800;
    text-transform: uppercase;
  }
  
  .notfound h2 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin: 0;
  }
  
  .notfound p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    font-style: italic;
    color: #00406b;
}
  
  .notfound button {
    font-family: 'Titillium Web', sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border: none;
    background: #5c91fe;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 1px;
    margin-top: 15px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
  }
  
  .notfound button:hover {
    opacity: 0.8;
  }
  
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      height: 110px;
      line-height: 110px;
    }
    .notfound .notfound-404 h1 {
      font-size: 120px;
    }
  }
  