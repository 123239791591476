.view_anchor-num {
    display: inline-block;
    height: 30px;
    float: left;
    line-height: 30px;
    text-align: right;
    text-decoration: none;
    color: #fff !important;
    font-weight: 500;
    position: relative;
    font-size: 12px;
    text-indent: 4px;
    font-style: normal;
    padding-right: 5px;
}
.view_anchor-num:after {
    content: "";
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left-width: 20px;
    border-left-style: solid;
    border-bottom: 15px solid transparent;
}

.backgroundAplus{
    width: 12%;
    background: #008000;
}
.backgroundAplus:after{
    border-left-color: #008000;
    border-right-color: #008000;
}
.backgroundA{
    width: 21%;
    background: #00BA52;
}
.backgroundA:after{
    border-left-color: #00BA52;
    border-right-color: #00BA52;
}
.backgroundB{
    width: 30%;
    background: #51B747;
}
.backgroundB:after{
    border-left-color: #51B747;
    border-right-color: #51B747;
}
.backgroundC{
    width: 40%;
    background: #BDD630;
}
.backgroundC:after{
    border-left-color: #BDD630;
    border-right-color: #BDD630;
}
.backgroundD{
    width: 50%;
    background: #FEF200;
}
.backgroundD:after{
    border-left-color: #FEF200;
    border-right-color: #FEF200;
}
.backgroundE{
    width: 60%;
    background: #FDB813;
}
.backgroundE:after{
    border-left-color: #FDB813;
    border-right-color: #FDB813;
}
.backgroundF{
    width: 70%;
    background: #F37030;
}
.backgroundF:after{
    border-left-color: #F37030;
    border-right-color: #F37030;
}


.certificate{
    width: 100%;
    padding: 2px;
    font-size: 14px;
}

.float-right{
    float: right;
}
.certificate-div3 {
    line-height: 1.3;
}

.certificate-div3 .table-energy-class{
    border: 2px solid #808080;
    border-radius: 4px;
    width: 100%
}
.certificate-div3 .table-energy-class tr td{
    border: 1px solid #808080;
    /* padding: 4px; */
}
.certificate-div3 .table-energy-class tr .header-energy{
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}
.certificate-div3 .table-energy-class tr .header-energy-ditales{
    font-size: 10px;
}
.certificate-div3 .table-energy-class .energy-tr{
    border-bottom-color: #FFF;
    padding: 1px;
}
.certificate-div3 .table-energy-class .energy-tr span{
    font-size: 10px;
}
.certificate-div3 .table-energy-class .energy-tr-image{
    background: #edeff2;
}

.certificate-div3 .table-energy-class tr td .energy-heating{
    font-size: 14px;
    font-weight: bold;
    float: right;
}

.view_energy-num {
    display: inline-block;
    height: 28px;
    float: left;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    color: #fff !important;
    font-weight: 500;
    position: relative;
    font-size: 12px;
    text-indent: 4px;
    font-style: normal;
    width: 50px;
}
.view_energy-num:after {
    content: "";
    position: absolute;
    right: 100%;
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-right-width: 20px;
    border-right-style: solid;
    border-bottom: 15px solid transparent;
}

.house-description {
    font-size: 14px;
}

/* .energi-discr-value {
    font-size: 0.6rem;
} */